import React from 'react'

import Layout from 'components/Layout'

const ErrorPage = () => (
  <Layout>
    <oma-grid-row>
      <section className='section'>
        <h2>Sidan hittades inte</h2>
        <p>
          Ajdå, den här sidan finns inte! Titta gärna in på en annan del av
          hemsidan istället. Om du hade förväntat dig något här så kontakta oss
          gärna.
        </p>
      </section>
    </oma-grid-row>
  </Layout>
)

export default ErrorPage
